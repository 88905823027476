export function loadScriptHead (trackerScript, async=true, defer=true) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = async
    script.defer = defer
    script.src = trackerScript

    const head = document.head || document.getElementsByTagName('head')[0]
    head.appendChild(script)

    script.onload = resolve
    script.onerror = event => {
      reject(new Error(`Failed to load ${event.target.src}`));
    };
  })
}

export function objectExist (name) {
  return new Promise((resolve) => {
    const checkInterval = 50
    const timeout = 3000
    const waitStart = Date.now()
    const interval = setInterval(() => {
      if (window[name]) {
        clearInterval(interval)
        return resolve()
      }
      if (Date.now() >= waitStart + timeout) {
        clearInterval(interval)
        throw new Error(`window.${name} undefined after waiting for ${timeout}ms`)
      }
    }, checkInterval)
  })
}

export function loadScriptBody (trackerScript, async = true, elementId = null) {
  console.log('Loading ', trackerScript)
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = async
    script.src = trackerScript
    if (elementId) {
      script.id = elementId
    }

    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(script, firstScript)
    script.onload = resolve
    script.onerror = (event) => {
      reject(new Error(`Failed to load ${event.target.src}`))
    }
  })
}
