

export default {
  components: {
    'id-lazy-chat': () => import('~/components/lazy/Chat.vue')
  },
  props: ['error'],
  data: () => ({
    errorMessages: {
      rate_limit: {
        title: 'Oh my,',
        subtitle: 'you are really committed. Aren\'t you?',
        msg: 'Repetitive events on the page were noticed, and a limiting scheme was triggered. Sometimes bots are the cause, and that slows down the store.',
        img: '/error-03.png'
      },
      429: {
        title: 'Oh my,',
        subtitle: 'you are really committed. Aren\'t you?',
        msg: 'Repetitive events on the page were noticed, and a limiting scheme was triggered. Sometimes bots are the cause, and that slows down the store.',
        img: '/error-03.png'
      },
      403: {
        title: 'Well,',
        subtitle: 'we can\'t let you in without authorization.',
        msg: 'This page is not publicly available.',
        img: '/error-04.png'
      },
      404: {
        title: 'Sorry',
        subtitle: 'the page you are looking for is not there.',
        msg: 'The link may be broken, page was removed or there is a typo in the URL.',
        img: '/error-01.png'
      },
      500: {
        title: 'Oops!',
        subtitle: 'That was surprising even for us.',
        msg: 'An unexpected error occurred, something is wrong on the server side.',
        img: '/error-02.png'
      }
    }

  }),
  computed: {
    message () {
      return this.errorMessages[this.error.statusCode]
    }
  },
  mounted () {
    console.log('Error page: ', this.error)
  },
  methods: {
    chatClick () {
      document.querySelectorAll('.woot-widget-bubble:not(.woot--close)')[0].click()
    },
    prevPage () {
      this.$router.back()
    }
  }
}
