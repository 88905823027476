import { loadScriptBody, objectExist } from '~/utils/plugins/helpers'

export default function install (Vue, setupOptions = {}) {
  const trackerScript = setupOptions.trackerScript || 'https://clients.infodiagram.com/mtc.js'
  const router = setupOptions.router
  window.MauticTrackingObject = 'mt'
  window.mt = window.mt || function () { (window.mt.q = window.mt.q || []).push(arguments) }
  loadScriptBody(trackerScript)
    .then(() => objectExist('MauticJS'))
    .then(() => {
      window.mt('send', 'pageview')
      Vue.prototype.$mautic = window.mt
      if (router) {
        router.afterEach(() => {
          // wait until router has resolved possible async components and hooks
          setTimeout(() => {
            mt('send', 'pageview')
          }, 1000)
        })
      }
    })

  for (const focusItem of setupOptions.focusItems || []) {
    loadScriptBody('https://clients.infodiagram.com/focus/' + focusItem)
      .then(() => { console.log('Loaded ' + focusItem + ' script') })
  }
}
