import { diagramToGA4Item } from '~/utils/helpers'

const state = () => ({
  wishlist: [],
  anonymousUserId: null
})

const mutations = {
  addFavourite (state, data) {
    state.wishlist.push(data)
  },
  removeFavourite (state, data) {
    state.wishlist = state.wishlist.filter(item => item.diagram.id !== data)
  },
  setAnonymousUserId (state, data) {
    state.anonymousUserId = data
  }
}

const getters = {
  isFavourite: state => (id) => {
    return state.wishlist.filter(item => item.diagram.id === id).length > 0
  },
  favouriteId: state => (diagramId) => {
    const favourite = state.wishlist.find(item => item.diagram.id === diagramId)
    if (favourite) {
      return favourite.favouriteId
    }
  }
}

const actions = {
  async addFavourite ({ commit, state, getters }, {
    diagram,
    $backend,
    $gtag,
    $matomo
  }) {
    if (getters.isFavourite(diagram.id)) {
      return
    }
    const params = {
      diagram: diagram.id,
      ...state.anonymousUserId ? { anonymous_user_id: state.anonymousUserId } : {}
    }

    const response = await $backend.favourites.post(params)
    const favouriteId = response.data.id
    $gtag.event('Add', { event_category: 'Favourite diagram', event_label: diagram.name })
    $gtag.event('add_to_wishlist', {
      send_to: [
        this.$config.ga4TrackingId
      ],
      items: [diagramToGA4Item(diagram, 0)]
    })
    $matomo && $matomo.trackEvent('Favourite diagram', 'Add', diagram.name)
    commit('addFavourite', { diagram, favouriteId })
    if (state.anonymousUserId === null) {
      commit('setAnonymousUserId', response.data.anonymous_user_id)
    }
  },
  async removeFavourite ({ commit, getters }, { diagram, $backend, $gtag, $matomo }) {
    const favouriteId = getters.favouriteId(diagram.id)
    if (favouriteId) {
      try {
        await $backend.favourites.delete(favouriteId)
        $gtag.event('Remove', { event_category: 'Favourite diagram', event_label: diagram.name })
        $matomo && $matomo.trackEvent('Favourite diagram', 'Remove', diagram.name)
      } catch (error) {
        console.log(error)
      }
    }
    commit('removeFavourite', diagram.id)
  }
}

export {
  state,
  actions,
  getters,
  mutations
}
