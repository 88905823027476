import { load } from 'recaptcha-v3'
import { apiPath } from '~/api/backend'

const postUrls = [
  `${apiPath}order/`,
  `${apiPath}payment/order/`,
  `${apiPath}subscription/`,
  `${apiPath}payment/subscription/`
]

const getUrls = [
  `${apiPath}payment/`
]

export default ({
  $config,
  $axios
}) => {
  $axios.onRequest(async (config) => {
    const { url, method } = config
    const useRecaptcha = (method == 'get' && getUrls.includes(url)) || (method == 'post' && postUrls.includes(config.url))

    if (useRecaptcha) {
      const recaptcha = await load($config.recaptchaKey)
      const token = await recaptcha.execute('ecommerce')
      config.headers['X-Recaptcha'] = token
    }

    return config
  })
}
