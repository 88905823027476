const state = () => ({
  showExitOverlay: false,
  relatedDiagrams: []
})

const mutations = {
  setRelatedDiagrams (state, diagrams) {
    state.relatedDiagrams = diagrams
  }
}

export { state, mutations }
