import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ store, app, $config }) => {
  let includes = []
  if ($config.production) {
    includes = [
      { id: $config.adsTrackingId },
      { id: $config.gaMeasurementId }
    ]
  }
  Vue.use(VueGtag, {
    includes,
    config: {
      debug: !$config.production,
      id: $config.gaMeasurementId
    },
    appName: process.env.PROJECT,
    bootstrap: store.state.cookie.consents.analytics,
    enabled: store.state.cookie.consents.analytics,
    pageTrackerTemplate: to => to.fullPath
  }, app.router)
}
