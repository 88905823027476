const state = () => ({
  cfOrder: {},
  cfCustomer: {}
})

const mutations = {
  updateOrder (state, value) {
    state.cfOrder = { ...value }
  },
  updateCustomer (state, value) {
    state.cfCustomer = { ...value }
  }
}

export { state, mutations }
