export default function ({ store, route }) {
  const utmParams = [
    'blog.infodiagram.com',
    'infodiagram_newsletter',
    'evergreen_newsletter',
    'mail_drip'
  ]
  if (
    process.env.browser && (
      utmParams.includes(route.query.utm_source) || document.referrer.includes('blog.infodiagram.com')
    )
  ) {
    store.commit('exitOverlay/setShowExitOverlay', false)
    store.commit('exitOverlay/setOverlayDisplayed', true)
  }
}
