const state = () => ({
  braintree: {},
  buttonDisabled: true,
  isLoadingPayment: false
})

const mutations = {
  setBraintree (state, braintree) {
    state.braintree = braintree
  },
  enableButton (state) {
    state.buttonDisabled = false
  },
  disableButton (state) {
    state.buttonDisabled = true
  },
  setLoading (state, loading) {
    state.isLoadingPayment = loading
  },
  removeLoading (state) {
    state.isLoadingPayment = false
  }
}

const actions = {
  async fetchToken ({ commit }, { backend }) {
    const braintree = await backend.payment.get()
    commit('setBraintree', braintree)
  }
}

export default { state, mutations, actions }
