import Vue from 'vue'
import VuexPersistence from 'vuex-persist'
import VueCarousel from 'vue-carousel'
import ClientOnly from 'vue-client-only'

export default ({ store }) => {
  new VuexPersistence({
    storage: window.localStorage,
    modules: [
      'cookie', 'favourites', 'checkout', 'exitOverlay', 'checkoutForm'
    ]
  }).plugin(store)
}
Vue.use(ClientOnly)
Vue.use(VueCarousel)
