export class ServerBackend {

  constructor (axios) {
    this.axios = axios
  }

  async fetchAll (url, params = {}) {
    const page = 1
    const { pageSize } = params
    const {
      data: {
        count,
        results
      }
    } = await this.fetchPage(url, {
      ...params,
      page
    })

    const totalPages = Math.ceil(count / pageSize)

    if (totalPages > 1) {
      const pagePromises = Array.from({ length: totalPages - 1 }, (_, i) =>
        this.fetchPage(
          url,
          {
            page_size: pageSize,
            page: i + 2
          })
      )

      const allResults = await Promise.all(pagePromises)

      // Flatten the results into a single array
      const additionalResults = allResults.flatMap(
        response => response.data.results
      )

      // Combine the first page results with the additional results
      return [...results, ...additionalResults]
    }
  }

  async fetchPage (url, params = {}) {
    const { pageSize } = params
    const {
      data: {
        count,
        results
      }
    } = await this.axios.get(
      url,
      {
        params: {
          page_size: pageSize || 10,
          ...params
        }
      }
    )
    return {
      results,
      count
    }
  }

  async get (endpoint, params = {}) {
    const url = `/website/v1${endpoint}`
    const { data } = await this.axios.get(
      url,
      {
        params
      }
    )
    return data
  }
}
