export default function ({
  route,
  redirect,
  $config
}) {
  if (route.path.startsWith('/transaction/')) {
    const orderKey = route.path.split('/')[2]
    return redirect(`/checkout/download/${orderKey}`)
  }
  if (/^\/order\/\w+\/download/.test(route.path)) {
    const orderKey = route.path.split('/')[2]
    return redirect(`/checkout/download/${orderKey}`)
  }
  if (route.path.startsWith('/newsletter/unsubscribe')) {
    return redirect(`${$config.apiHost}${route.fullPath}`)
  }
  if (route.path.startsWith('/freesample')) {
    return redirect(`/diagrams/${$config.freeSampleUrl}/`)
  }
  if (route.path.startsWith('/howitworks')) {
    return redirect('/faq/')
  }
}
