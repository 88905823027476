const state = () => ({
  plans: [],
  subscription: null,
  plan: null
})

const mutations = {
  setPlans (state, data) {
    state.plans = data
  },
  setPlan (state, plan) {
    state.plan = plan
  },
  setSubscription (state, subscription) {
    state.subscription = subscription
  }
}

const getters = {
  singlePlans: (state) => {
    return state.plans.filter(plan => plan.user_limit === 1).sort((first, second) => first.ordering - second.ordering)
  },
  teamPlans: (state) => {
    return state.plans.filter(plan => plan.user_limit > 1).sort((first, second) => first.ordering - second.ordering)
  }
}

const actions = {
  async fetchPlans ({ commit }, { $backend }) {
    commit('setPlans', await $backend.plans.list())
  },
  async createSubscription ({ commit, state }, { backend, customer, subscription }) {
    subscription.plan = state.plan.id
    subscription.payment_cycle_months = state.plan.payment_cycle_months
    const response = await backend.subscription.post(
      { customer, subscription }
    )
    commit('setSubscription', response.data)
    return response.data
  }
}

export {
  state, actions, getters, mutations
}
