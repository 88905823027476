import Vue from 'vue'
import VueMatomo from 'vue-matomo'

export default ({ app, $config }) => {
  Vue.use(VueMatomo, {
    router: app.router,
    host: 'https://piwik.infodiagram.com',
    siteId: $config.matomo.siteId,
    enableHeartBeatTimer: true,
    debug: $config.matomo.debug,
    cookieDomain: $config.matomo.cookieDomain,
    domains: $config.matomo.domains,
    preInitActions: [
      ['setCustomDimension', 1, 'nuxt']
    ],
    requireConsent: false,
    requireCookieConsent: true,
    trackSiteSearch: (to) => {
      if (to.name === 's-query') {
        return { keyword: to.params.query }
      } else {
        return null
      }
    }
  })
}
