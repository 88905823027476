export class CartItem {
  constructor (diagram) {
    this.diagram = diagram
    this.price = diagram.price
    this.licensedPrice = this.price
    this.discount = 0
    this.code = null
    this.singleUserLicense = {
      id: null,
      description: 'Single user'
    }
    this.license = this.singleUserLicense
    this.specialOffer = null
  }
}
