import type { ProviderGetImage } from '@nuxt/image'
import type { Format } from '@imgproxy/imgproxy-js-core'
import { generateUrl } from '@imgproxy/imgproxy-js-core'

function encode (src: string) {
  if (process.server) {
    return Buffer.from(src).toString('base64')
  }
  try {
    return btoa(src)
  } catch (e) {
    console.error(e)
    console.error("Can't encode image URL, ", src)
    return src
  }
}

function log (message: string) {
  if (process.server && !process.static) {
    console.log(message)
  }
}

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {} }) => {
  const {
    width,
    format
  } = modifiers
  const url = generateUrl(
    {
      type: 'base64',
      value: encode(src)
    },
    {
      width,
      format: format as Format
    }
  )
  return {
    url: `/api/img${url}`
  }
}
