const state = () => ({
  activeSlideIndex: 0
})

const mutations = {
  setActiveSlideIndex (state, value) {
    state.activeSlideIndex = value
  }
}

export { state, mutations }
