export const apiPath = '/website/v1/'

async function paginate (axios, url, pageSize = 20) {
  let next = true
  let page = 1
  let results = []
  while (next) {
    const data = await axios.get(url, { params: { page, page_size: pageSize } }).then(r => r.data)
    results = [...results, ...data.results]
    next = data.next !== null
    page += 1
  }
  return results
}

export default $axios => endpoint => ({
  async list (pageSize = 20) {
    const results = await paginate($axios, `${apiPath}${endpoint}/`, pageSize)
    return results
  },
  retrieve (id, nestedEndpoint = null) {
    let url = `${apiPath}${endpoint}/${id}`
    if (nestedEndpoint) {
      url = `${url}/${nestedEndpoint}/`
    }
    return $axios.get(url).then(r => r.data)
  },
  async retrieveAll (id, nested) {
    const url = `${apiPath}${endpoint}/${id}/${nested}/`
    const results = await paginate($axios, url)
    return results
  },
  image (id) {
    return $axios.get(`${apiPath}${endpoint}/${id}/image/`)
  },
  page (next) {
    if (next === undefined) {
      next = `${apiPath}${endpoint}/`
    }
    return $axios.get(next)
  },
  get (params) {
    return $axios.get(`${apiPath}${endpoint}/`, { params }).then(r => r.data)
  },
  post (params) {
    return $axios.post(`${apiPath}${endpoint}/`, params)
  },
  delete (id) {
    return $axios.delete(`${apiPath}${endpoint}/${id}/`)
  },
  async paginate (callback) {
    let next = `${apiPath}${endpoint}/`
    while (true) {
      const response = await $axios.get(next)
      callback(response.data.results)
      if (response.data.next) {
        next = response.data.next
      } else {
        break
      }
    }
  }
})
