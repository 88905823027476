const state = () => ({
  showExitOverlay: false,
  overlayDisplayed: false,
  accessToken: null
})

const mutations = {
  setShowExitOverlay (state, value) {
    state.showExitOverlay = value
  },
  setOverlayDisplayed (state) {
    state.overlayDisplayed = true
  },
  setAccessToken (state, value) {
    state.accessToken = value
  }
}

export { state, mutations }
