import { cartItemToAnalyticsItem, cartItemToGA4Item, diagramToGA4Item } from '@/utils/helpers'
import { CartItem } from '@/models/checkout'

const state = () => ({
  order: null,
  licenses: [],
  cart: [],
  modals: {
    addedToCart: {
      visible: false,
      diagram: null,
      relatedDiagrams: []
    },
    preview: {
      visible: false,
      diagram: null,
      images: []
    }
  }
})

const mutations = {
  addToCart (state, cartItem) {
    if (!state.cart.find(item => item.diagram.id === cartItem.diagram.id)) {
      state.cart.push(cartItem)
    }
  },
  updateCartItem (state, data) {
    const itemIndex = state.cart.findIndex(item => item.diagram.id === data.diagram_id)
    if (data.license === null) {
      data.license = {
        description: 'Single user',
        id: null
      }
    }
    state.cart[itemIndex].license = data.license
    state.cart[itemIndex].price = data.final_price
    state.cart[itemIndex].discount = data.discount
    state.cart[itemIndex].licensedPrice = data.licensed_price
    state.cart[itemIndex].code = data.code
  },
  removeFromCart (state, diagramId) {
    state.cart = state.cart.filter(item => item.diagram.id !== diagramId)
  },
  emptyCart (state) {
    state.cart = []
  },
  setAddedToCartModal (state, data) {
    state.modals.addedToCart = data
  },
  setPreviewItem (state, data) {
    state.modals.preview = data
  },
  setLicenses (state, data) {
    state.licenses = data
  },
  setOrder (state, data) {
    state.order = data
  },
  setOrderPaid (state, order) {
    state.order.status = 'PAID'
    state.order.key = order.key
  },
  setBundle (state, bundle) {
    state.bundle = bundle
  }
}

const getters = {
  bundleDiscount: (state, getters) => {
    // this console log seem to enforce some sort refresh of state.
    console.log('Calculating bundle discount ', state.bundle, ' items in cart ', state.cart)
    return state.bundle && state.bundle.discount && getters.bundleDiagramsInCart ? state.bundle.discount : 0
  },
  subTotal: (state) => {
    return state.cart.map(item => parseFloat(item.licensedPrice)).reduce(
      (total, price) => total + price,
      0
    )
  },
  total: (state, getters) => {
    const totalItemsPrice = state.cart.map(item => parseFloat(item.price)).reduce((total, itemPrice) => {
      total += itemPrice
      return total
    }, 0)
    const totalPrice = totalItemsPrice - getters.bundleDiscount
    return Math.round(totalPrice * 100) / 100
  },
  discount: (state, getters) => {
    const itemsDiscounts = state.cart.map(item => parseFloat(item.discount)).reduce(
      (total, discount) => total + discount,
      0
    )
    return itemsDiscounts + getters.bundleDiscount
  },
  bundleDiagramsInCart: (state) => {
    if (!state.bundle) {
      return false
    }
    for (const diagram of state.bundle.diagrams) {
      if (!state.cart.find(item => item.diagram.id === diagram.id)) {
        return false
      }
    }
    return true
  },
  bundleId: (state, getters) => {
    return state.bundle && getters.bundleDiagramsInCart ? state.bundle.id : null
  }
}

const actions = {
  async fetchLicenses ({ commit }, { $backend }) {
    commit('setLicenses', await $backend.licenses.list())
  },
  async addCodeToCartItems ({ commit, state, getters }, { code, $backend, $matomo }) {
    const items = (await $backend.cartItemsPrice.post(
      state.cart.map(item => ({
        diagram_id: item.diagram.id,
        license_id: item.licenseId,
        code,
        special_offer_id: item.specialOffer ? item.specialOffer.id : null
      })
      ))).data
    items.forEach(item => commit('updateCartItem', item))
    updateMatomoCart(state.cart, $matomo, getters.total)
  },
  addDiagramToCart ({ commit, state, getters }, { diagram, $gtag, $matomo, $bing, license, specialOffer }) {
    const cartItem = new CartItem(diagram)
    if (license) {
      cartItem.license = license
      cartItem.licensedPrice = cartItem.price *= license.multiplier
    }
    if (specialOffer && specialOffer.diagram.id === diagram.id) {
      cartItem.specialOffer = specialOffer
      cartItem.discount = specialOffer.discount
      cartItem.price -= specialOffer.discount
    }

    $gtag.event(
      'add_to_cart',
      {
        items: [cartItemToAnalyticsItem(cartItem)]
      }
    )

    $gtag.event(
      'add_to_cart',
      {
        send_to: [
          this.$config.ga4TrackingId
        ],
        items: [cartItemToGA4Item(cartItem)]
      }
    )

    commit('addToCart', cartItem)
    updateMatomoCart(state.cart, $matomo, getters.total)
    $matomo && $matomo.trackEvent('Cart', 'Add', diagram.name)
    $bing && $bing.event(
      {
        ecomm_prodid: [diagram.name],
        ecomm_pagetype: 'product',
        ecomm_totalvalue: cartItem.price,
        revenue_value: cartItem.price,
        currency: 'USD',
        items: [
          {
            id: cartItem.diagram.id,
            quantity: 1,
            price: cartItem.price
          }
        ]
      },
      'add_to_cart'
    )
  },
  removeDiagramFromCart ({ commit, state, getters }, { diagram, $gtag, $matomo }) {
    commit('removeFromCart', diagram.id)
    $gtag.event(
      'remove_from_cart', {
        send_to: [
          this.$config.ga4TrackingId
        ],
        items: [diagramToGA4Item(diagram)]
      }
    )
    $matomo && $matomo.trackEvent('Cart', 'Remove', diagram.name)
    updateMatomoCart(state.cart, $matomo, getters.total)
  },
  async createOrder ({ commit, state, getters }, { $backend, customer, order }) {
    const bundles = getters.bundleId ? [getters.bundleId] : []
    const newOrder = await $backend.order.post(
      {
        customer,
        order: { ...order, bundles },
        cart_items: state.cart.map(item => ({
          diagram_id: item.diagram.id,
          license_id: item.license ? item.license.id : null,
          code: item.code,
          special_offer_id: item.specialOffer ? item.specialOffer.id : null
        }))
      }
    ).then(response => response.data)
    commit('setOrder', newOrder)
  },
  async showAddedToCartModal ({ commit }, { $backend, diagram }) {
    commit('setAddedToCartModal',
      {
        relatedDiagrams: await $backend.relatedDiagrams.retrieve(diagram.id),
        diagram,
        visible: true
      })
  },
  hideAddedToCartModal ({ commit }) {
    commit('setAddedToCartModal', {
      visible: false
    })
  },
  async setItemPreview ({ commit }, { visible, diagram, $backend }) {
    let images = null
    if (diagram) {
      images = await $backend.diagrams.retrieveAll(diagram.id, 'images')
    }
    commit('setPreviewItem', {
      visible,
      diagram,
      images
    })
  },
  async updateCartItemLicense ({ commit, state, getters }, { item, licenseId, $backend, $matomo }) {
    const itemId = item.diagram ? item.diagram.id : item.id
    const items = await $backend.cartItemsPrice.post(
      [
        {
          diagram_id: itemId,
          code: item.code || null,
          license_id: licenseId,
          special_offer_id: item.specialOffer ? item.specialOffer.id : null
        }
      ]
    )
    items.data.forEach(data => commit('updateCartItem', data))
    updateMatomoCart(state.cart, $matomo, getters.total)
  }
}

export {
  state,
  actions,
  getters,
  mutations
}

function updateMatomoCart (cartItems, matomo, total) {
  if (matomo) {
    cartItems.forEach((cartItem) => {
      matomo.addEcommerceItem(
        cartItem.diagram.id,
        cartItem.diagram.name,
        cartItem.license ? cartItem.license.description : 'Single User',
        cartItem.price,
        1
      )
    })
    matomo.trackEcommerceCartUpdate(total)
  }
}
