import Vue from 'vue'
import VueMautic from '~/utils/plugins/mautic'

export default ({ $config, app }) => {
  if ($config.production) {
    Vue.use(
      VueMautic,
      {
        trackerScript: 'https://clients.infodiagram.com/mtc.js',
        focusItems: [
          '1.js'
        ],
        router: app.router
      })
  }
}
