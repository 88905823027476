const state = () => ({
  visible: true,
  consents: {}
})

const mutations = {
  setVisible (state, visible) {
    state.visible = visible
  },
  setConsents (state, { marketing, personalization, analytics }) {
    state.consents.marketing = marketing || false
    state.consents.personalization = personalization || false
    state.consents.analytics = analytics || false
  }
}

export { state, mutations }
