import { loadScriptHead, objectExist } from '~/utils/plugins/helpers'

class Bing {
  constructor (uet) {
    this.uet = uet
  }

  event (data, type = '') {
    window.uetq.push('event', type, data)
  }
}

const defaultOptions = {
}

export default function install (Vue, setupOptions = {}) {
  const options = Object.assign({}, defaultOptions, setupOptions)
  const { tagId } = options
  window.uetq = window.uetq || []
  loadScriptHead('https://bat.bing.com/bat.js')
    .then(() => objectExist('UET'))
    .then(() => {
      window.uetq = window.uetq || []
      window.uetq = new window.UET({ ti: tagId, q: window.uetq })
      window.uetq.push('pageLoad')
      Vue.prototype.$bing = new Bing(window.uetq)
    })
    .catch((error) => {
      console.log(error)
    })
}
